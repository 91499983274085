<template>
  <div class="newDetail">
    <Head hId="2"></Head>
    <div class="main">
      <div class="content">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/news' }">新闻资讯</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="newContent ql-editor">
          <h1>{{newDetailContent.title}}</h1>
          <i>
            <span
              ><img
                src="@/assets/img/Index_JobSeeker/icon29.png"
                alt=""
              />{{newDetailContent.viewCount}}</span
            ><span
              ><img
                src="@/assets/img/Index_JobSeeker/icon17.png"
                alt=""
              />{{newDetailContent.createTime}}发布</span
            >
          </i>
          <img
            class="newImg"
            :src="newDetailContent.picture"
            alt=""
          />
          <div v-html="unEscapeHTML(newDetailContent.content)">
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { newDetail } from "@/api/new";
export default {
  name: "newDetail",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      newDetailContent: null,
    };
  },
  mounted() {
    console.log(this.$route.query.id);
    this.getNewDetail();
  },
  methods: {
     unEscapeHTML(html) {
        html= "" +html;
        return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'");
    },
    getNewDetail() {
      newDetail(this.$route.query.id).then((res) => {
        this.newDetailContent = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.newDetail {
  .main{
    min-height: calc(100vh - 278px);
  }
  .el-breadcrumb {
    margin: 26px 0;
  }
  .newContent {
    padding: 24px 16px;
    background: #ffffff;
    margin-bottom: 66px;
    min-height: 800px;
    h1 {
      height: 55px;
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      line-height: 55px;
      text-align: center;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 29px;
      box-sizing: content-box;
    }
    i {
      font-style: normal;
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
      text-align: center;
      margin: 29px 40px;
      display: block;
      img {
        vertical-align: middle;
        margin-right: 8px;
        width: 18px;
      }
      span {
        &:nth-child(1) {
          margin-right: 32px;
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 32px;
      margin-bottom: 40px;
    }
  }
}
/deep/ img{
  max-width: 100%;
}
</style>
